import React from "react";
import WidgetGrid from "../../../components/widget/WidgetGrid";
import PlatformWidget from "../../app/dashboard/views/PlatformWidget";
import StatusWidget from "../../app/dashboard/views/StatusWidget";
import NumberStatsWidget from "../../app/dashboard/views/NumberStatsWidget";
import { useCurrentProjectStats } from "../../../store/project/projectHooks";
import { PieChartOptions } from "../../../components/charts/PieChart";

interface PdfOverviewPageProps {}

function PdfOverviewPage(props: PdfOverviewPageProps) {
  const { stats, loading } = useCurrentProjectStats();
  const options: PieChartOptions = {
    // legendPosition: "bottom",
    compact: true,
  };
  return (
    <>
      <WidgetGrid>
        <PlatformWidget
          loading={loading}
          byPlatform={stats?.byPlatform}
          options={options}
        />
        <StatusWidget
          loading={loading}
          byStatus={stats?.byStatus}
          options={options}
        />
      </WidgetGrid>
      <NumberStatsWidget stats={stats} />
    </>
  );
}

export default PdfOverviewPage;
