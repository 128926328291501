import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface ExplainProps {
  text?: string;
  children?: string;
  size?: string;
  color?: string;
  multiline?: boolean;
}

function Explain(props: ExplainProps) {
  return (
    <Container
      className={classNames("has-tooltip-bottom", {
        "has-tooltip-multiline": props.multiline,
      })}
      data-tooltip={props.text || props.children}
      data-balloon-length={"small"}
      size={props.size}
    >
      <FontAwesomeIcon
        icon={faQuestionCircle}
        size={"xs"}
        color={props.color || "#747474"}
      />
    </Container>
  );
}

const Container = styled.span<Pick<ExplainProps, "size" | "color">>`
  margin-left: 4px;
  svg {
    font-size: ${(props) => props.size};
    vertical-align: text-top;
  }
`;

export default Explain;
