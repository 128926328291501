import React from "react";
import { number } from "prop-types";
import ProjectStatsSection from "../../app/dashboard/sections/stats/ProjectStatsSection";
import { ProjectSelectionContext } from "../../../elements/context/project/ProjectSelectionContext";
import PdfScreen from "../../../layouts/pdf/PdfScreen";
import PdfPage from "../../../layouts/pdf/PdfPage";
import PdfStatsOverview from "../stats/PdfOverviewPage";
import PdfProjectHeader from "../PdfProjectHeader";
import BestVideosSection from "../../app/dashboard/sections/bestVideos/BestVideosSection";
import PdfFooter from "../../../layouts/pdf/layout/PdfFooter";

interface PdfDashboardProps {}

function PdfDashboardScreen(props: PdfDashboardProps) {
  const pages = [
    <PdfStatsOverview key={"overview"} />,
    <ProjectStatsSection key={"stats"} inlineExplain />,
    <BestVideosSection key={"best"} />,
  ];

  return (
    <ProjectSelectionContext resolveFromUrl>
      <PdfScreen>
        {pages.map((page, i) => (
          <Page key={i} number={i + 1} maxPages={pages.length}>
            {page}
          </Page>
        ))}
      </PdfScreen>
    </ProjectSelectionContext>
  );
}

function Page(props: { children: any; number: number; maxPages: number }) {
  return (
    <PdfPage
      header={<PdfProjectHeader />}
      footer={<PdfFooter number={props.number} maxPages={props.maxPages} />}
    >
      {props.children}
    </PdfPage>
  );
}

// noinspection JSUnusedGlobalSymbols
export default PdfDashboardScreen;
